import '../../../App.css';
// components
import {
  RegisterForm
} from '../components';

// ----------------------------------------------------------------------

export default function RegisterView() {
  return (
    <RegisterForm/>
  );
}
