import '../../../App.css';
// components
import {
  LoginForm
} from '../components';

// ----------------------------------------------------------------------

export default function LoginView() {
  return (
    <LoginForm/>
  );
}
