// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: "AIzaSyDlBq75FgR2sa20Ap6PHdqzGg58LwNnfdM",
  authDomain: "bspconsult-bcd6e.firebaseapp.com",
  projectId: "bspconsult-bcd6e",
  storageBucket: "bspconsult-bcd6e.appspot.com",
  messagingSenderId: "209328620753",
  appId: "1:209328620753:web:a42e728f0d5cd74101f1f0",
  measurementId: "G-RXQB0FWLHX",
};


export const GOOGLE_MAP_API = process.env.REACT_APP_MAP_API;

// LAYOUT
// ----------------------------------------------------------------------

export const NAV = {
  W_BASE: 260,
  W_DRAWER: 280,
  //
  H_ITEM: 48,
  H_ITEM_SUB: 36,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
};
